export default function tableColumns() {
  const oldColumns = [
    {
      thClass: 'text-center',
      label: 'K/L',
      field: 'prefix',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'Nomor Surat Permohonan',
      field: 'letter_number',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Tanggal Surat Rekomendasi',
      field: 'tanggal_surat',
      width: '135px',
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop Kominfo',
      field: 'no_surat_kominfo',
      width: '200px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop MenpanRB',
      field: 'no_surat_panrb',
      width: '200px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Daftar Aplikasi / Infrastruktur',
      field: 'surat_id',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
  ]

  const newColumns = [
    {
      thClass: 'text-center',
      label: 'K/L',
      field: 'prefix',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'Nomor Surat Permohonan',
      field: 'letter_number',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Tanggal Surat Rekomendasi',
      field: 'tanggal_surat',
      width: '135px',
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop Kominfo',
      field: 'no_surat_kominfo',
      width: '200px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop MenpanRB',
      field: 'no_surat_panrb',
      width: '200px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop Bappenas',
      field: 'no_surat_bapenas',
      width: '200px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Daftar Item Belanja',
      field: 'surat_id',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
  ]

  return {
    oldColumns,
    newColumns,
  }
}
